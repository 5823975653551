import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 800px;
  position: relative;
  z-index: 1;
 
`;


export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 32px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

export const Form = styled.form`
  background: #fff;
  max-width: 500px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 50px 32px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

  @media screen and (max-width: 480px) {
    padding: 32px 32px;
  }
`;

export const FormH1 = styled.h1`
  margin-bottom: 40px;
  color: #333;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

export const FormLabel = styled.label`
  margin-bottom: 8px;
  color: #333;
  font-size: 14px;
`;

export const FormInput = styled.input`
  padding: 12px 12px;
  margin-bottom: 16px;
  border: 1px solid #9A8E8A;
  border-radius: 4px;

  &:focus {
    outline: none !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
        
  }
`;

export const FormTextArea = styled.textarea`
  padding: 12px 12px;
  
  border: 1px solid #9A8E8A;
  resize: none;
  border-radius: 4px;

  &:focus {
    outline: none !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
  }
  
`;

export const FormButton = styled.button`
  background: #01bf71;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;

  :disabled {
    opacity: 0.4;
  }
`;

export const Text = styled.span`
  text-align: center;
  padding-bottom: 24px;
  color: #333;
  font-size: 16px;
  color: #01bf71;
  font-weight: 400;
`;


export const RecaptchaWrap = styled.div` 
display: flex;
align-items: center;
justify-content: center;
margin:  32px auto;

`