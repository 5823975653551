import { ThemeProvider } from 'styled-components';
import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
// import GlobalStyles from './components/styles/Global';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';

const theme = {
  color: {
    green: '#01bf71',
    white: '#fff',
    brown: '#3A1311',
    transparent: 'trransparent'
  },
  screen: {
    phone: '480px',
    tablet: '768px',
    desktop: '1024px',
    wideScreen: '1366px',
  },
};

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        {/* <GlobalStyles /> */}
        <Router>
          <Sidebar isOpen={isOpen} toggle={toggle} />
          <Navbar toggle={toggle} />
          <Routes>
            <Route path="/" element={<Home />} exact />
          </Routes>
          <Footer />
        </Router>
      
      </>
    </ThemeProvider>
  );
}

export default App;
