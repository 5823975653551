import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObj, principlesObj, debtSerchObj, pricesObj, preventionObj } from '../components/InfoSection/Data';
import Services from '../components/Services';
import Contact from '../components/Contact';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      {/* <Navbar toggle={toggle}/> */}
      <HeroSection/>
      <InfoSection {...homeObj}/>
      <InfoSection {...principlesObj}/>
      <Services/>
      <InfoSection {...debtSerchObj}/>
      <InfoSection {...preventionObj}/>
      <InfoSection {...pricesObj}/>
      <Contact/>
      {/* <Footer/> */}
    </>
  );
};

export default Home;
