export const homeObj = {
  id: 'about',
  returnPoint: 'principles',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Apie mus',
  mainLine: '',
  topline1: 'Veiklos Kryptys',

  headline0: '',
  headline:
    'UAB „Inter Credit“ – skolų išieškojimo įmonė teikia skolų prevencijos, skolininkų mokumo, skolų pirkimo bei kitas teisines paslaugas. UAB „Inter Credit“ savo veiklą pradėjo 2008 m. ir šiuo metu yra subūrusi profesionalią teisininkų, finansininkų bei analizės specialistų komandą. Tikime, jog UAB „Inter Credit“ darbuotojų įgytos patirties bei kompetencijos dėka galėsime Jums suteikti kokybiškas paslaugas bei efektyviai apginti teisėtus Jūsų interesus.',
  description:
    'UAB „Inter Credit“ atstovauja klientą skolų išieškojimo bei kituose teisminiuose santykiuose neperžengdami teisės, etikos bei moralės normų. Stengdamiesi mažinti vėluojančius atsiskaitymus ir jų nevykdymo pasekmes taikome patikimas, specifines skolų išieškojimo priemones, kurių dėka klientas, taupydamas savo laiką, finansus bei energiją gali susikoncentruoti ties pagrindine savo veikla (ties verslo konkurencinės bei finansinės aplinkos vystymu).',
  buttonLabel: 'Daugiau',
  subjectLines: [
    { line: 'Skolų išieškojimas bei atsiskaitymų priežiūra;' },
    { line: 'Skolininkų mokumo vertinimas;' },
    { line: 'Skolų pirkimas - pardavimas;' },
    { line: 'Atstovavimas teismuose;' },
    { line: 'Kitos teisinės paslaugos.' },
    { line: '' },
  ],
  descriptions: [
    {
      headLine: 'Veiklos Kryptys',
      description: '',
    },
    {
      headLine: '',
      description: '',
    },
    {
      headLine: '',
      description: '',
    },
    {
      headLine: '',
      description: '',
    },
    {
      headLine: '',
      description: '',
    },
  ],
  buttonHide: false,
  imgStart: false,
  alt: 'Car',
  dark: false,
  primary: false,
  darkText: true,
};

export const principlesObj = {
  id: 'principles',
  returnPoint: 'contact',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Apie mus',
  mainLine: 'Principai ir Vertybės',
  headline:
    'Vadovavimasis UAB „Inter Credit“ veiklos principais ir vertybėmis padeda klientams sukurti konkurentabilesnę bei saugesnę verslo aplinką, o mums nuolat pritraukti naujus klientus bei išlikti lojaliems.',
  headline0:
    'UAB „Inter Credit“ veikla pagrįsta šiais principais bei vertybėmis:',
  subjectLines: [{ line: '' }],

  descriptions: [
    {
      headLine: 'Sąžiningumas ir pagarba',
      description:
        'Sąžiningas ir garbingas bendradarbiavimas bei požiūris i verslą – tai pagarba sau, kolegoms, klientams, partneriams ir konkurentams, sukuriant darbui palankią aplinką bei ilgalaikę naudą klientui.',
    },
    {
      headLine: 'Kokybė',
      description:
        'Dėmesingi ir kompetentingi darbuotojai, kiekvieną dieną tobulindami savo įgūdžius, garantuoja paslaugų kokybiškumą ir profesionalumą.',
    },
    {
      headLine: 'Pasitikėjimas ir atsakomybė',
      description:
        'Kiekvieno darbuotojo pastangos atsakingai ir patikimai dirbti leidžia vadovams nedvejojant prisiimti atsakomybę už įsipareigojimus klientui.',
    },
    {
      headLine: 'Individualumas',
      description:
        'Kiekvieno kliento problema yra unikali, todėl atsižvelgdami į konkrečią situaciją atliekame išsamią ir išskirtinę analizę - pradedant kliento poreikių identifikavimu bei tikslų nustatymu.',
    },
    {
      headLine: 'Konfidencialumas',
      description:
        'Konfidencialumas yra svarbi prielaida skatinanti pasitikėjimą tarp įmonės ir kliento. Šis saugumo principas reglamentuoja griežtą požiūrį į informacijos slaptumą, asmeniškumą ir privatumą.',
    },
    {
      headLine: 'Novatoriškumas',
      description:
        'Stengiamės pastoviai mokytis ir tobulėti. Nuolatinis žinių atnaujinimas, gilinimas, pažangiausių idėjų panaudojimas veikloje leidžia sėkmingai konkuruoti rinkoje ir garantuoja efektyvesnį klientų aptarnavimą.',
    },
  ],

  buttonLabel: 'Susisiekti',
  buttonHide: true,
  imgStart: false,
  alt: 'Car',
  dark: false,
  primary: false,
  darkText: true,
};

export const debtSerchObj = {
  id: 'pds',
  returnPoint: 'services',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Paslaugos',
  mainLine: 'Skolų Išieškojimas',
  headline:
    'UAB „Inter Credit” atstovauja klientą skolų išieškojimo bei kituose teisminiuose santykiuose neperžengdami teisės, etikos bei moralės normų. Stengdamiesi mažinti vėluojančius atsiskaitymus ir jų nevykdymo pasekmes taikome patikimas, specifines skolų išieškojimo priemones, kurių dėka klientas, taupydamas savo laiką, finansus bei energiją gali susikoncentruoti ties pagrindine savo veikla.',
  subjectLines: [{ line: '' }],
  descriptions: [
    {
      headLine: 'Ikiteisminis įsiskolinimų išieškojimas',
      description:
        'Ikiteisminis skolų išieškojimo procesas apima informacijos apie skolininko veiklos ir būklės analizę bei įvertinimą, derybomis pagrįstų santykių su skolininku užmegizmu ar bankroto bylos inicijavimu. Sėkmingai išieškojus įsiskolinimą skolų išieškojimo biurui, pagal bendrą susitarimą, mokamas sėkmės mokestis, kurio dydis priklauso nuo įsiskolinimo sumos dydžio, jos laikotarpio bei sudėtingumo. Naudojant efektyvias ikiteismines skolos išieškojimo priemones Jūs didele dalimi užsitikrinate pasiekti pageidautiną rezultatą, išlaikydami gerus santykius su klientais ir nepakenkdami įmonės įvaizdžiui.',
    },
    {
      headLine: 'Teisminis įsiskolinimų išieškojimas',
      description:
        'Įsiskolinimų nepavykus išieškoti ikiteisminiu keliu, taikomos teisminės įsiskolinimo išieškojimo priemonės - nuo dokumentų teismui parengimo, pateikimo, atstovavimo teisme ar kitose institucijose iki skolos išieškojimo vykdymo kontrolės.',
    },
    {
      headLine: 'Poteisminis įsiskolinimų išieškojimas',
      description:
        'Šis etapas apima skolos išieškojimo administravimą bei tolimesnį įsiskolinimo išieškojimo vykdymą kuomet jau yra užvesta vykdomoji byla pas antstolį. Teisingai parenkant bendradarbiavimo su antstoliu metodiką bei pritaikant netipines skolos išieškojimo priemones, skolos atgavimo tikimybė ženkliai išauga.',
    },
    {
      headLine: 'Vekseliai',
      description:
        'Vekselis – skolos dokumentas, kurį išrašęs asmuo be sąlygų įsipareigoja sumokėti tam tikrą pinigų sumą vekselyje nurodytam asmeniui. Vekselis – tai vienas greičiausių būdų išieškant skolas. Tokiu būdu apeinamas teismas, efektyviai ir greitai atgaunamos skolos. UAB „Inter Credit“ specialistai teikia visas, su vekselio paruošimu bei išieškojimu susijusias paslaugas.',
    },
    {
      headLine: 'Netipinės skolų išieškojimo priemonės',
      description:
        'Kai kurioms nestandartinėms situacijoms nepakanka taikyti įprastas skolų išieškojimo priemones, todėl norėdami didinti rezultatų efektyvumą, atskirais atvejais taikome netipines skolų išieškojimo priemones atitinkančias teisines bei etikos normas. Esant poreikiui yra atliekama vizitacija pas skolininką, jo nekilnojamo ar kilnojamo turto paieška bei kita paslauga, paskatinanti savalaikį skolos atgavimą.',
    },
    {
      headLine: 'Skolų pirkimas',
      description:
        'Skolų pirkimas – dar vienas instrumentas, suteikiantis Jūsų įmonei galimybę ne tik atsikratyti rūpesčių susijusių su skola, bet ir „užšaldytas“ lėšas paversti apyvartinėmis. UAB „Inter Credit“ skolas perka nepriklausomai nuo jos išieškojimo etapo, todėl kiekvieną pirkimo pardavimo sandorį derina su klientu individuliai.',
    },
    {
      headLine: 'Beviltiškų skolų nurašymas',
      description:
        'Konsultuojame ir padedame parengti dokumentus susijusius su skolų nurašymo klausimais.',
    },
  ],
  buttonHide: false,
  buttonLabel: 'Atgal',
  imgStart: true,
  alt: 'Car',
  dark: false,
  primary: false,
  darkText: true,
};

export const preventionObj = {
  id: 'psp',
  returnPoint: 'services',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Paslaugos',
  mainLine: 'Skolų Prevencija',
  headline: 'Skolų susidarymo prevencinės priemonės:',
  subjectLines: [{ line: '' }],
  descriptions: [
    {
      headLine: 'Verslo informacijos paslaugos',
      description:
        'Tai aktuali informacija susijusi su Jus dominančio ūkio subjekto veikla: monitoringas, mokumo įvertinimas, dalyvavimo teismo procesuose fakto nustatymas, kontaktiniai duomenys ir kt. Ši prevencijos priemonė suteiks galimybę objektyviau atlikti verslo subjektų analizę bei parinkti tinkamas priemones bendradarbiavimui ir patikimumui nustatyti.',
    },
    {
      headLine: 'UAB „Inter Credit“ spaudas',
      description:
        'UAB „Inter Credit“ spaudo naudojimas ant įmonės sąskaitų – efektyvi prevencinė priemonė užtikrinanti savalaikius atsiskaitymus. Spaudas gali būti naudojamas tiek ant tiesioginių, tiek ant elektroniniu būdu siunčiamų sąskaitų. Užsakant spaudą galima pasirinkti spaudo dydį, spalvą bei patį tekstą. Spaudo naudojimas, mūsų įmonės klientams, yra nemokamas.',
    },
    {
      headLine: 'Atsiskaitymų priežiūra',
      description:
        'Stengiantis padėti išvengti apyvartinių lėšų trūkumo siūlome atsiskaitymų priežiūros paslaugą, kuri paspartina išankstinius apmokėjimus bei sumažina skolos atsiradimo riziką. Priminimai, bendravimas telefonu ar reguliarių apsilankymų atlikimas pas vėluojančius atsiskaityti klientus yra itin svarbi prevencinė priemonė skatinanti verslo partnerius laikytis atsiskaitymų disciplinos.',
    },
  ],
  buttonHide: false,
  buttonLabel: 'Atgal',
  imgStart: false,
  alt: 'Car',
  dark: false,
  primary: false,
  darkText: true,
};

export const pricesObj = {
  id: 'prices',
  returnPoint: 'contact',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Kainos',
  mainLine: '',
  headline:
    'Kiekvieno kliento situacija yra unikali, tad reikalauja išsamios bei išskirtinės analizės - pradedant kliento poreikių identifikavimu, tikslų ir norų nustatymu.',
  headline0:
    'Paslaugų įkainiai yra tiesiogiai priklausomi nuo įsiskolinimo sumos dydžio, sudėtingumo, nuo įsiskolinimo laikotarpio, skolininko mokumo bei kitų svarbių veiksnių. Skolų išieškojimo biurui pagal bendrą susitarimą, mokamas atitinkamas sėkmės mokestis.',
  subjectLines: [
    {
      line: 'Nemokamos juridinių ir fizinių asmenų konsultacijos teisiniais klausimais;',
    },
    { line: 'Optimalaus ginčo sprendimo būdo parinkimas;' },
    { line: 'Sutarčių rengimas;' },
    { line: 'Kreditorių interesų apsauga;' },
    {
      line: 'Tarpininkavimas ir pagalba kreditų bendrovių (greitųjų kreditų, bankų, kredito unijų ir kt.) Skolininkams grąžinant skolas;',
    },
    {
      line: 'Konsultacijos įmonių bankroto, reorganizavimo, likvidavimo klausimais.',
    },
  ],

  descriptions: [
    {
      headLine: 'Teisinės paslaugos',
      description:
        'Atstovaujame juridinių ir fizinių asmenų interesus teismuose, antstolių kontorose bei kitose valstybinėse ir privačiose institucijose ar organizacijose;',
    },
    {
      headLine: '',
      description: 'Rengiame procesinius dokumentus teismui.',
    },
    {
      headLine: 'Kitos paslaugos',
      description: '',
    },
  ],

  topLine1: 'Paslaugos',
  buttonHide: false,
  buttonLabel: 'Susisiekti',
  imgStart: true,
  alt: 'Car',
  dark: false,
  primary: false,
  darkText: true,
};
