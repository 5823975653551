import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';
import {
  Container,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  FormTextArea,
  RecaptchaWrap,
  Text,
} from './ContactElements';

const Contact = () => {
  const recaptchaRef = useRef(null);
  const [isVerified, setIsVerified] = useState(false);
  const [result, showResult] = useState(false);

  function onChange(value) {
    setIsVerified(true);
  }

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_ynlanvm',
        'template_0veq1j9',
        e.target,
        'user_2bM4hZMv3UBh5XcjP7aN3'
      )
      .then(
        (response) => {
          showResult(true);
        },
        (err) => {

        }
      );

    recaptchaRef.current.reset();
    e.target.reset();
    setIsVerified(false);
  }

  setTimeout(() => {
    showResult(false);
  }, 5000);

  return (
    <>
      <Container  id="contact">
        <Form onSubmit={sendEmail}>
          <FormH1>Susisiekite su mumis</FormH1>
          <FormLabel htmlFor="name">Vardas</FormLabel>
          <FormInput type="text" name="name" required />
          <FormLabel htmlFor="email">El. pašto adresas</FormLabel>
          <FormInput type="email" name="email" required />
          <FormLabel htmlFor="message">Žinutė</FormLabel>
          <FormTextArea
            rows="6"
            name="message"
            maxLength="3000"
            required
          ></FormTextArea>
          <RecaptchaWrap>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Leud18eAAAAAD-uMKV7Qwd-pF4SvYLz-JB4bz4L"
              onChange={onChange}
            />
          </RecaptchaWrap>
          <Text>{result ? 'Jūsų žinutė išsiųsta.' : <>&nbsp;</>}</Text>
          <FormButton disabled={!isVerified} type="submit">
            Siųsti
          </FormButton>
        </Form>
      </Container>
    </>
  );
};

export default Contact;
