import React from 'react';
import { Button } from '../ButtonElements';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  BtnWrapper,
  Column1,
  Subtitle,
  Heading,
  Column2,
  TopLine,
  TextWrapper,
  PointLine,
  HeadingSub,
} from './InfoElements';

const InfoSection = ({
  id,
  lightText,
  lightBg,
  imgStart,
  mainLine,
  mainLine0,
  topLine,
  topLine1,
  headline,
  headline0,
  buttonHide,
  darkText,
  descriptions,
  subjectLines,
  buttonLabel,
  returnPoint,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                { mainLine0 ? <HeadingSub lightText={lightText}>{mainLine0}</HeadingSub> : null}
                { mainLine ? <Heading lightText={lightText}>{mainLine}</Heading> : null}
                <Subtitle darkText={darkText}>{headline}</Subtitle>
                <Subtitle darkText={darkText}>{headline0}</Subtitle>
                {buttonHide ? '' :
                <BtnWrapper >
                  <Button
                    to={returnPoint}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel}
                  </Button>
                </BtnWrapper>}
              </TextWrapper>
            </Column1>
            <Column2>
              <TextWrapper>
                {topLine1 ? <TopLine>{topLine1}</TopLine> : null}

                {descriptions.map((item, i) => (
                  <>
                   {item.headLine ?  <HeadingSub lightText={lightText} key={`${id}_head_${i}`}>{item.headLine}</HeadingSub> : null}
                   {item.description ? <Subtitle darkText={darkText} key={`${id}_sub_${i}`}>{item.description}</Subtitle> : null }
                  </>
                  ))}


                {subjectLines.map((item, i) => (
                  <>
                   {item.line ?  <PointLine darkText={darkText} key={`${id}_line_${i}`}>{item.line}</PointLine> : null}
                  </>
                  ))}

              </TextWrapper>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
