import React from 'react';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterLinkd,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from './FooterElements';
import { animateScroll as scroll } from 'react-scroll';


const Footer = () => {

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>UAB „Inter credit“</FooterLinkTitle>
              <FooterLinkd>K. Nemuno 28-1A, Kaunas, 44289, Lietuva</FooterLinkd>
              <FooterLinkd>Įmonės kodas: 301687760</FooterLinkd>
              <FooterLinkd>+370 37 339308</FooterLinkd>
              <FooterLinkd>+370 659 91098</FooterLinkd>
              <FooterLinkd>+370 659 91097</FooterLinkd>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Atsiskaitomoji sąskaita</FooterLinkTitle>
              <FooterLinkd>Sąskaitos numeris: </FooterLinkd>
              <FooterLinkd>LT07 7044 0600 0635 7837</FooterLinkd>
              <FooterLinkd>AB bankas SEB Bankas</FooterLinkd>
              <FooterLinkd>Banko kodas: 70440</FooterLinkd>
              <FooterLinkd>BIC SWIFT: CBVILT2X</FooterLinkd>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Apie mus</FooterLinkTitle>
              <FooterLink to="about" smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}>Veiklos Kryptys</FooterLink>
              <FooterLink to="principles" smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}>Principai ir Vertybės</FooterLink>
              <FooterLinkd></FooterLinkd>
              <FooterLinkd></FooterLinkd>
              <FooterLinkd></FooterLinkd>
              <FooterLinkd></FooterLinkd>
              <FooterLinkd></FooterLinkd>
              <FooterLinkd></FooterLinkd>
              <FooterLinkd></FooterLinkd>
              <FooterLinkd></FooterLinkd>
              <FooterLinkd></FooterLinkd>
              <FooterLinkd></FooterLinkd>
              <FooterLinkd></FooterLinkd>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Paslaugos</FooterLinkTitle>
              <FooterLink to="pds" smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}>Skolų Išieškojimas</FooterLink>
              <FooterLink to="psp" smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}>Skolų Prevencija</FooterLink>
              <FooterLink to="prices" smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}>Kitos Paslaugos</FooterLink>
              <FooterLink to="prices" smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}>Kaina</FooterLink>
              
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <WebsiteRights>Inter credit &copy; 2008-{new Date().getFullYear()}. Visos teisės saugomos.</WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
