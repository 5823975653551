import React from 'react';
import Icon1 from '../../images/icon1.svg';
import Icon2 from '../../images/icon2.svg';
import Icon3 from '../../images/icon3.svg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServiceElements';

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Paslaugos</ServicesH1>
      <ServicesWrapper>
        <ServicesCard  to="pds"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Skolų Išieškojimas</ServicesH2>
          <ServicesP>Atstovaujame klientą skolų išieškojime</ServicesP>
        </ServicesCard>
        <ServicesCard  to="psp"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Skolų Prevencija</ServicesH2>
          <ServicesP>Ūkio subjekto veiklos monitoringas</ServicesP>
        </ServicesCard>
        <ServicesCard to="prices"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Kitos Paslaugos</ServicesH2>
          <ServicesP>Sutarčių rengimas, atstovavimas teisme</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
